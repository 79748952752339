import React from "react";
import Layout from "../components/layout";
import Obras1 from "../images/execucaodeobras/Obras1.jpg";
import Obras10 from "../images/execucaodeobras/Obras10.jpg";
import Obras2 from "../images/execucaodeobras/Obras2.jpg";
import Obras3 from "../images/execucaodeobras/Obras3.jpg";
import Obras4 from "../images/execucaodeobras/Obras4.jpg";
import Obras5 from "../images/execucaodeobras/Obras5.jpg";
import Obras6 from "../images/execucaodeobras/Obras6.jpg";
import Obras7 from "../images/execucaodeobras/Obras7.jpg";
import Obras8 from "../images/execucaodeobras/Obras8.jpg";
import Obras9 from "../images/execucaodeobras/Obras9.jpg";
import ObrasBanner from "../images/execucaodeobras/ObrasBanner.jpg";
import SEO from "../components/seo";

function ExecucaoDeObras() {
  return (
    <Layout>
      <SEO
        keywords={[`Golden`, `Sondagens`, `SPT`, `Construção`]}
        title="Execução de obras"
      />

      <section
        style={{
          height: "300px",
          backgroundImage: `linear-gradient(to right, hsla(0, 0%, 0%, 1), hsla(0, 0%, 0%, 0.3)), url(${ObrasBanner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
        }}
      >
        <h1 className="font-bold text-white ml-8 mt-24 uppercase base-title-golden">
          Execução de Obras
        </h1>
      </section>

      <div className="flex">
        <div className="text-justify my-20 mx-6 md:mx-14 xl:mx-40">
          <h3>
            A <b>Golden</b> atua nas diversas áreas da construção civil e conta
            com uma equipe capacitada que se preocupa em atender as necessidades
            do seu cliente. Contamos com profissionais experientes, capacitados
            e atuamos com obras de pequeno e grande porte.
          </h3>

          <h2 className="font-bold text-center base-golden-center my-12">
            Nossas obras
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 my-12">
            <img src={Obras1} className="img-zoom" />
            <img src={Obras2} className="img-zoom" />
            <img src={Obras3} className="img-zoom" />
            <img src={Obras4} className="img-zoom" />
            <img src={Obras5} className="img-zoom" />
            <img src={Obras6} className="img-zoom" />
            <img src={Obras7} className="img-zoom" />
            <img src={Obras8} className="img-zoom" />
            <img src={Obras9} className="img-zoom" />
            <img src={Obras10} className="img-zoom" />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ExecucaoDeObras;
